<script>
import { getReview, setReviewStatus } from "@/api/reviews";

export default {
  name: "ReviewsItem",
  states: {
    new: "Новые",
    interest: "Whatsapp",
    decision: "Отправлена ссылка",
    action: "Проставлен отзыв",
    reject: "Отказ",
  },
  data() {
    return {
      loading: false,
      basis: null,
      client: null,
      products: [],

      rating_status: null,
      rating: null,
      ratingComment: null,
    };
  },
  computed: {
    orderCode() {
      return this.$route.params.review_id;
    },
  },
  async mounted() {
    await this.getReview();
  },
  methods: {
    async getReview() {
      const { review } = await getReview(this.orderCode);
      this.products = review.products;
      this.basis = this.orderCode;

      this.rating_status = review.rating_status;
      this.rating = review.rating;
      this.ratingComment = review.rating_comment;

      this.client = {
        phone: review.phone,
        displayName: review.first_name + " " + review.last_name,
      };
    },
    async openWhatsappLink() {
      this.loading = true;
      await setReviewStatus(this.orderCode, "interest");
      this.rating_status = "interest";
      this.loading = false;

      this.$emit("move", {
        orderCode: this.orderCode,
        from: "new",
        to: "interest",
      });
      window.open(`https://wa.me/7${this.client.phone}`, "_blank");
    },
    async copyLink(kaspiSku) {
      this.loading = true;
      await setReviewStatus(this.orderCode, "decision");
      this.rating_status = "decision";
      this.loading = false;

      this.$emit("move", {
        orderCode: this.orderCode,
        from: "interest",
        to: "decision",
      });

      const text = `https://kaspi.kz/shop/review/productreview?orderCode=${this.basis}&productCode=${kaspiSku}&started_by=shop_order_details&rating=5`;
      await navigator.clipboard.writeText(text);
      this.$message.success(`Скопировано`);
    },
    async reject() {
      this.loading = true;
      await setReviewStatus(this.orderCode, "reject");
      this.rating_status = "reject";
      this.loading = false;

      this.$emit("move", {
        orderCode: this.orderCode,
        from: "interest",
        to: "reject",
      });
    },
    close() {
      this.$router.push({ name: "reviews" });
    },
  },
};
</script>

<template>
  <el-drawer
    title=""
    :visible="true"
    :with-header="false"
    :before-close="close"
    size="500px"
    class="island-drawer"
  >
    <div class="flex flex-col h-full">
      <header
        v-if="client"
        class="border-0 border-b border-solid border-gray-200 bg-gray-100 py-2.5 px-5 font-medium flex items-start justify-between"
      >
        <div>
          <div class="text-gray-500">{{ client.displayName }}</div>
          <div>+7{{ client.phone }}</div>
        </div>
        <div>
          <div class="text-gray-500">
            {{ $options.states[rating_status] || "Новые" }}
          </div>
          {{ orderCode }}
        </div>
      </header>
      <main class="h-full overflow-y-auto px-5 py-3">
        <div v-if="!rating_status || rating_status === 'new'" class="state-new">
          <el-button
            @click="openWhatsappLink"
            type="primary"
            plain
            size="small"
            class="w-full"
          >
            Перейти в whatsapp
          </el-button>
        </div>
        <div v-if="rating_status === 'interest'" class="state-interest">
          <div class="border border-solid border-gray-200 rounded text-xs">
            <div
              class="border-0 border-b border-solid border-gray-200 bg-gray-100 px-3 py-1.5"
            >
              Если клиент доволен продуктом и хочет оставить отзыв, то
              скопируйте ссылку на отзыв и отправьте клиенту:
            </div>
            <div class="px-3 py-2">
              <ul>
                <li
                  v-for="p in products"
                  :key="p.id"
                  class="flex items-center justify-between text-xs"
                >
                  <div class="mr-4">
                    {{ p.name }}
                  </div>
                  <el-button
                    :disabled="loading"
                    :icon="loading ? 'el-icon-loading' : ''"
                    @click="() => copyLink(p.kaspi_sku)"
                    size="small"
                    round
                    type="primary"
                    plain
                    title="Скопировать"
                    >Скопировать ссылку на отзыв</el-button
                  >
                </li>
              </ul>
            </div>
          </div>

          <div
            class="mt-10 border border-solid border-gray-200 rounded text-xs"
          >
            <div
              class="border-0 border-b border-solid border-gray-200 bg-gray-100 px-3 py-1.5"
            >
              Если клиент не доволен продуктом:
            </div>
            <div class="px-3 py-2 text-center">
              <el-button
                @click="reject"
                :disabled="loading"
                :icon="loading ? 'el-icon-loading' : ''"
                size="small"
                type="danger"
                plain
                round
                >Отказ</el-button
              >
            </div>
          </div>
        </div>
        <div v-if="rating_status === 'decision'">
          <div class="text-gray-500 mt-2 text-center">
            Ждем рейтинга от клиента
          </div>
        </div>
        <div v-if="rating_status === 'action' && rating">
          <el-rate
            v-model="rating"
            disabled
            show-score
            text-color="#ff9900"
            aria-setsize="small"
          >
          </el-rate>
          <div class="mt-2">{{ ratingComment }}</div>
        </div>
      </main>
    </div>
  </el-drawer>
</template>

<style scoped lang="scss">
section {
  & + section {
    @apply mt-5;
  }
}

.state-interest {
  ul li {
    & + li {
      @apply mt-2.5;
    }
  }
}
</style>
